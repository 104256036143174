// import Vue form 'vue'

export default {
  namespaced: true,
  state: {
    cardLists: ['11111', '22222'],
    cardNum: ''
  },
  mutations: {
    setSearch (state, value) {
      state.cardNum = value.cardNum
    }
  },
  actions: {

  }
}
