<template>
  <div class="page">
    首页
    <div @click="$router.push('/search')">充值查询</div>
     <div @click="$router.push('/recharge')">充值界面</div>
    <div @click="$router.push('/realname')">实名认证</div>
    <div @click="$router.push('/agreement')">协议文章</div>
    <div @click="$router.push('/card/info')">卡内信息</div>
    <div @click="$router.push('/usercenter')">个人中心</div>
    <div @click="$router.push('/help')">帮助中心</div>
  </div>
</template>

<script>
export default {
  name: 'home',
  mounted () {
    document.title = this.$route.meta.title
  }
}
</script>

<style lang="scss" scoped>

</style>
