import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import search from './modules/search'
import realname from './modules/realname'
import usercenter from './modules/usercenter'
import help from './modules/help'
import rechange from './modules/rechange'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    search,
    realname,
    usercenter,
    help,
    rechange
  }
})
