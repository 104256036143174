const medules = {
  namespaced: true,
  state: {
    userData: {
      pic: '',
      cellphone: '13800138000',
      activation: true,
      isreal: true,
      operator: 'mobile',
      effectiveDate: '2021年6月30日',
      allFlow: 120,
      remainder: 40

    }
  },
  mutations: {},
  actions: {}
}

export default medules
