import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home'

const Search = () => import('@/views/search')
const RealName = () => import('@/views/realname')
const Agreement = () => import('@/views/aggrement')
const CardInfo = () => import('@/views/card')
const Recharge = () => import('@/views/recharge/index')
const SetMeal = () => import('@/views/recharge/set-meal')
const Package = () => import('@/views/recharge/package.vue')
const Pay = () => import('@/views/pay')
const UserCenter = () => import('@/views/usercenter/index')
const FlowCard = () => import('@/views/usercenter/card')
const Record = () => import('@/views/usercenter/record')
const Help = () => import('@/views/help/index.vue')
const Template = () => import('@/views/help/template')
// 防止相同路由点击报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: '物联卡商城' }
  },
  {
    path: '/search',
    component: Search,
    meta: { title: '充值查询' }
  },
  {
    path: '/realname',
    component: RealName,
    meta: { title: '实名认证' }
  },
  {
    path: '/agreement',
    component: Agreement,
    meta: { title: '协议文章' }
  },
  {
    path: '/card/info',
    component: CardInfo,
    meta: { title: '卡内信息' }
  },
  {
    path: '/recharge',
    component: Recharge,
    children: [
      {
        path: '',
        component: SetMeal,
        name: 'recharge',
        meta: { title: '充值-流量套餐' }
      },
      {
        path: 'package',
        name: 'package',
        component: Package,
        meta: { title: '充值-流量包' }
      }
    ]
  },
  {
    path: '/pay',
    component: Pay,
    meta: { title: '支付' }
  },
  {
    path: '/usercenter',
    component: UserCenter,
    redirect: '/usercenter/card',
    children: [
      {
        path: 'card',
        name: 'user-card',
        component: FlowCard,
        meta: { title: '流量卡' }
      },
      {
        path: 'record',
        name: 'user-record',
        component: Record,
        meta: { title: '充值记录' }
      }
    ]
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
    redirect: '/help/common',
    children: [
      {
        path: '/help/:catalog',
        name: 'help-list',
        component: Template
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
