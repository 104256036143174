export default {
  namespaced: true,
  state: {
    navList: [
      {
        name: '常见问题',
        path: '/help/common'
      },
      {
        name: '充值问题',
        path: '/help/recharge'
      },
      {
        name: '上网故障',
        path: '/help/net'
      },
      {
        name: '流量问题',
        path: '/help/flow'
      },
      {
        name: '实名认证',
        path: '/help/real'
      },
      {
        name: '设备设置',
        path: '/help/device'
      }
    ]
  },
  mutations: {

  },
  actions: {}
}
