export default {
  namespaced: true,
  state: {
    lists: [
      {
        title: '月包',
        values: [
          {
            num: '40',
            price: 29,
            isTop: false
          },
          {
            num: '80',
            price: 58,
            isTop: true
          },
          {
            num: '200',
            price: 129,
            isTop: false
          }
        ]
      },
      {
        title: '季包 9折',
        values: [
          {
            num: '40',
            price: 29,
            isTop: false
          },
          {
            num: '80',
            price: 58,
            isTop: true
          },
          {
            num: '200',
            price: 129,
            isTop: false
          }
        ]
      },
      {
        title: '年包 8折',
        values: [
          {
            num: '40',
            price: 29,
            isTop: false
          },
          {
            num: '80*12',
            price: 58,
            isTop: true
          },
          {
            num: '200',
            price: 129,
            isTop: false
          }
        ]
      }
    ],
    total: 0,
    isActive: false
  },
  mutations: {
    // 数据新增 active
    setList (state) {
      if (state.lists.length > 0) {
        for (let i = 0; i < state.lists.length; i++) {
          for (let j = 0; j < state.lists[i].values.length; j++) {
            if (i === 0 && j === 0) {
              state.lists[i].values[j].active = true
              state.total = state.lists[i].values[j].price
              state.isActive = true
            } else {
              state.lists[i].values[j].active = false
            }
          }
        }
      }
    },
    selectItem (state, value) {
      if (state.lists.length > 0) {
        for (let i = 0; i < state.lists.length; i++) {
          for (let j = 0; j < state.lists[i].values.length; j++) {
            if (state.lists[i].values[j].active) {
              state.lists[i].values[j].active = false
            } else {
              state.isActive = false
            }
          }
        }
      }
      state.lists[value.index].values[value.index2].active = true
      state.total = state.lists[value.index].values[value.index2].price
      state.isActive = true
    }
  },
  actions: {}
}
