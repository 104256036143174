export default {
  namespaced: true,
  state: {
    lists: [
      {
        num: '948594854958',
        state: true,
        name: '240GB包年套餐',
        cellphone: '13800138000',
        style: '支付宝',
        price: '99',
        date: '2021.5.31  21:20'
      }
    ]
  },
  mutations: {},
  actions: {}
}
